import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TextContainer } from "../components/Container"

const Shop = () => (
  <Layout>
    <SEO title="Shop" meta={[{ name: "robots", content: "noindex" }]} />
    <TextContainer>
      <h1 style={{ textAlign: "center" }}>Shop</h1>
      <p>Coming soon&hellip;</p>
    </TextContainer>
  </Layout>
)

export default Shop
